import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container"

// Icon
import QuoteIcon from "assets/icons/talent-discovery/icon-quote.inline.svg"

const StyledQuote = styled.section`
  position: relative;
  padding: 64px 0px;
  background-color: ${colors.silver__extra_pale};

  ${breakpoints.medium`
  padding: 132px 0px 110px 0px;
  `}

  svg {
    position: absolute;
    bottom: 0;
    right: 0px;
  }
  .image-container {
    margin: 0px 24px 24px 0px;

    ${breakpoints.medium`
    margin: 0px 24px 0px 40px;
    `}

    .image {
      width: 256px;
      height: 256px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${colors.turquoise__primary};
      border-radius: 50%;
      padding: 4px;
    }
  }

  .section {
    margin-right: 40px;
  }
`

const Quote = () => {
  const { faceImage, logo, separator } = useStaticQuery(graphql`
    query {
      faceImage: file(relativePath: { eq: "talent-discovery/brittany.png" }) {
        childImageSharp {
          gatsbyImageData(width: 233, quality: 100)
        }
      }
      logo: file(relativePath: { eq: "talent-discovery/logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 111, quality: 100)
        }
      }
      separator: file(relativePath: { eq: "talent-discovery/separator.png" }) {
        childImageSharp {
          gatsbyImageData(width: 2, height: 59, quality: 100)
        }
      }
    }
  `)
  return (
    <StyledQuote>
      <Container>
        <div className="d-flex flex-column flex-md-row">
          <div className="col-12 col-md-3 image-container">
            <div className="image d-flex">
              <GatsbyImage image={getImage(faceImage)} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              <h1 className="h1 mb-4">
                “We are grateful for and value the additional applicant flow
                Talent Discovery brings to our communities.”
              </h1>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="section">
                  <GatsbyImage image={getImage(logo)} alt="" />
                </div>
                <div className="section">
                  <GatsbyImage image={getImage(separator)} alt="" />
                </div>
                <div className="section">
                  <h2 className="h2 color--navy__extra_dark">
                    Brittany Balouris
                  </h2>
                  <p className="color--silver__primary">
                    Director of Team Member Experience and Engagement
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block">
            <QuoteIcon />
          </div>
        </div>
      </Container>
    </StyledQuote>
  )
}
export default Quote
