import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"
import Quote from "sections/talent-discovery/quote"

// Sections
import Hero from "sections/talent-discovery/hero"
import RetainableCandidates from "sections/talent-discovery/retainable-candidates"
import RediscoverTalent from "sections/talent-discovery/rediscover-talent"

const TalentDiscovery = () => (
  <Layout showNewsletter={false}>
    <SEO title="Talent Discovery | Arena Analytics" />
    <Hero />
    <RetainableCandidates />
    <RediscoverTalent />
    <Quote />
  </Layout>
)

export default TalentDiscovery
