import React from "react"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Styles
import styled from "styled-components"

// Components
import Container from "components/container/"

// Icons
import Prioritice from "assets/icons/talent-discovery/icon-know.inline.svg"
import Engage from "assets/icons/talent-discovery/icon-re-engage.inline.svg"
import Money from "assets/icons/talent-discovery/icon-save-money.inline.svg"

const StyledRediscover = styled.section`
  background-color: ${colors.navy__extra_dark};
  padding: 64px 0;
  color: ${colors.white};

  ${breakpoints.medium`
    padding: 143px 0 170px 0;
  `}

  span {
    color: ${colors.turquoise__light};
  }

  .subtitle {
    margin-bottom: 40px;

    ${breakpoints.medium`
      margin-bottom: 56px;
    `}
  }

  .col-12 {
    ${breakpoints.large`
      padding-right: 16px;
      padding-left: 16px;
    `}
  }

  .card {
    height: 100%;
    padding: 40px;
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    ${breakpoints.medium`
      padding: 56px 57px 67px 57px;
    `}
  }
`

const RediscoverTalent = () => {
  const dataTalent = [
    {
      icon: <Prioritice />,
      caracteristic: "Re-engage applicants you already know",
    },
    {
      icon: <Engage />,
      caracteristic: "Re-engage applicants who already know your organization",
    },
    {
      icon: <Money />,
      caracteristic: "Save money on sourcing new applicants",
    },
  ]
  return (
    <StyledRediscover id="rediscovery" className="text-center">
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <h2 className="h2 maxi mb-2 mb-md-3">
              <span>Rediscover Talent</span>
              <br />
              in Your System
            </h2>
            <p className="text--large color--silver__light mb-4">
              With <strong>Talent ReDiscovery</strong>, we mine your Applicant
              Tracking System (ATS) to identify previously unhired candidates,
              match them to the jobs where they will stay, and nurture them to
              apply to your hard-to-fill roles.
            </p>
            <h3 className="h3 font-weight--700 subtitle">
              Arena Talent ReDiscovery helps organizations:
            </h3>
          </div>
        </div>

        <div className="row p-0">
          {dataTalent.map((data) => (
            <div className="col-12 col-sm-6 col-md-4 mb-4 mb-lg-0">
              <div className="card">
                {data.icon}
                <h3 className="h3 font-weight--700 color--navy__extra_dark mt-5">
                  {data.caracteristic}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </StyledRediscover>
  )
}

export default RediscoverTalent
