import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
// import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container"

// Icons
import IconCheck from "assets/icons/talent-discovery/icon-check.svg"
import IconRetainable from "assets/icons/talent-discovery/icon-retainable.inline.svg"
import breakpoints from "utils/breakpoints"
// import IconCheck from "assets/icons/our-platform/icon-check.svg"

const StyledRetainableCandidates = styled.section`
  display: flex;
  background-color: ${colors.silver__extra_pale};
  padding: 64px 16px 32px 16px;

  ${breakpoints.medium`
  padding: 65px 0px 98px 0px;
  `}

  .heading-section {
    h2 {
      ${breakpoints.medium`
        margin-bottom: 40px;
        font-size: 48px;
        line-height: 57px;
        `}
    }
  }

  h3 {
    margin-bottom: 18px;
  }

  .items-section {
    .icon-retainable {
      position: relative;
      bottom: 190px;
      left: -50px;
      margin-bottom: -190px;
    }
  }

  .items-container {
    background-color: ${colors.white};
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 64px;
    margin-top: 32px;

    ${breakpoints.medium`
      padding: 56px;
      margin-top: 0;
    `}
  }

  .item {
    display: flex;
    margin-bottom: 24px;

    &::before {
      content: url("${IconCheck}");
      width: 24px;
      height: 24px;
      position: relative;
      display: inline-block;
      margin-right: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      display: inline-block;
      margin-left: 16px;
      font-weight: 600;
    }
  }
`

const RetainableCandidates = () => (
  <StyledRetainableCandidates id="retainableCandidates">
    <Container>
      <div className="row justify-content-between align-items-center">
        <div className="col-12 col-md-6">
          <div className="heading-section">
            <h2 className="h2 maxi">
              Delivering Quality,{" "}
              <span className="color--turquoise__primary">
                Retainable Candidates
              </span>
            </h2>
            <p className="text--large mt-3">
              Backed by the groundbreaking Arena Analytics Retention Prediction,{" "}
              <span className="font-weight--700">Talent Discovery</span> finds
              and delivers quality candidates who are predicted to stay in your
              organization, and actively engages past applicants.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="items-section">
            <div className="items-container">
              <h3 className="h3">
                To help expand your talent pool, the Talent Discovery solution
                includes:
              </h3>
              <ul>
                <li className="item d-flex flex-direction-row align-items-center">
                  <p>Professional recruitment marketing at scale</p>
                </li>
                <li className="item d-flex flex-direction-row align-items-center">
                  <p>Delivery of engaged, ready-to-interview candidates</p>
                </li>
                <li className="item d-flex flex-direction-row align-items-center">
                  <p>Best hiring practices advisory consultation</p>
                </li>
              </ul>
            </div>
            <IconRetainable className="icon-retainable d-none d-md-block" />
          </div>
        </div>
      </div>
    </Container>
  </StyledRetainableCandidates>
)

export default RetainableCandidates
